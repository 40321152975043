<template>
  <div class="content">
    <div class="content_banner">
      <div class="banner">
        <div
          :class="'info_banner' + (index + 1)"
          v-for="(item, index) in bannerList"
          :key="index"
        >
          <div class="info_banner_box" style="" @click="toInfo(item)">
            <img :src="item.apparatusFileId" />

            <div class="banner_text">
              {{ item.apparatusName }}
            </div>
            <i class="iconfont icon-biaoqian" v-if="index == 0"></i>
            <div class="num">{{ item.apparatusType }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 热门榜单 -->
    <div class="content_hottop">
      <div class="hottop">
        <div class="hottop_c">
          <content-title
            :first="'热门榜单'"
            :scende="'查看更多'"
            :to="'/top/'"
          ></content-title>
          <div class="hottop_c_top">
            <div
              class="topbox"
              v-for="(item, index) in topList"
              :key="index"
              @click="to('/top/info', item.rankId)"
            >
              <div class="topbox_t">
                <div class="topbox_t_title"></div>
                <div class="topbox_t_image">
                  <img :src="item.rankIcon" alt="" />
                </div>
                <div class="imgbox bd">
                  <img src="../assets/top-1.png" alt="" v-if="index == 0" />
                </div>
                <div class="imgbox bd">
                  <img src="../assets/top-2.png" alt="" v-if="index == 1" />
                </div>
                <div class="imgbox bd">
                  <img src="../assets/top-3.png" alt="" v-if="index == 2" />
                </div>
                <div class="imgbox bd">
                  <img src="../assets/top-4.png" alt="" v-if="index == 3" />
                </div>
                <div class="imgbox bd">
                  <img src="../assets/top-5.png" alt="" v-if="index == 4" />
                </div>
              </div>
              <div class="topbox_b">
                <div class="topbox_b_title h1" :title="item.titleRankName">
                  {{ item.rankName }}
                </div>
                <!-- <div class="topbox_b_title p">{{ item.paramKey }}</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 器械资讯 -->
    <div class="content_instrument">
      <div class="instrument">
        <div class="instrument_c">
          <content-title
            :first="'器械资讯'"
            :scende="'查看更多'"
            :to="'/info/'"
          ></content-title>
          <release-layout
            :data="newList"
            :row="4"
            :id="'layout1'"
            :to="path"
          ></release-layout>
        </div>
      </div>
    </div>
    <!-- 大众测评 -->
    <div class="content_check">
      <div class="check">
        <div class="check_c">
          <content-title
            :first="'大众评测'"
            :scende="'查看更多'"
            :to="'/check/'"
          ></content-title>
          <release-layout
            :data="commentList"
            :row="3"
            :id="'layout2'"
            :to="path1"
            :type="'check2'"
          ></release-layout>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div class="returnTop" v-show="showTop">
        <div class="box" @click="returnTop">
          <img src="../assets/returnTop.png" alt="" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import releaseLayout from "../components/releaseLayout.vue";
import contentTitle from "../components/title.vue";
import api from "../api/api";
export default {
  data() {
    return {
      bannerList: [],
      topList: [],
      newList: [],
      AssessList: [],
      commentList: [],
      path: {
        url: "/info/textinfo",
      },
      path1: {
        url: "/check/product/info",
        isId: 1,
      },
      showTop: false,
      interval: null,
    };
  },
  components: { releaseLayout, contentTitle },
  created() {
    this.banner();
    this.top();
    this.indexTopNews();
    // this.indexTopAssess();
    this.indexTopAComment();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    toInfo(item) {
      console.log(item);
      this.$router.push(
        `/instrumentlib/info/${item.apparatusId}/${item.apparatusCatId}`
      );
    },
    returnTop() {
      let distanceY = window.pageYOffset;
      let i = 0;
      this.interval = setInterval(() => {
        let next = Math.floor(
          this.easeInOutQuad(10 * i, distanceY, -distanceY, 500)
        );
        if (next <= 0) {
          window.scrollTo(0, 0);
          clearInterval(this.interval);
        } else {
          window.scrollTo(0, next);
        }
        i++;
      }, 17);
    },
    easeInOutQuad(t, b, c, d) {
      // 判断当前时间是否总在总时间的一半以内，是的话执行缓入函数，否则的话执行缓出函数
      if ((t /= d / 2) < 1) {
        return (c / 2) * t * t + b;
      } else {
        // 将总长度设置为一半，并且时间从当前开始递减，对图像进行垂直向上平移
        return (-c / 2) * (--t * (t - 2) - 1) + b;
      }
    },

    to(path, id) {
      this.$router.push(`${path}/${id}`);
    },
    goto(item) {
      this.$router.push(
        `/instrumentlib/info/${item.apparatusId}/${item.apparatusCatId}`
      );
    },
    banner() {
      api.indexTopApparatus().then((res) => {
        this.bannerList = res.data.data.data.slice(0, 3);
      });
    },
    top() {
      api.indexTopRank().then((res) => {
        let data = res.data.data.data;
        data.forEach((item) => {
          if (item.rankName.length > 6) {
            item.titleRankName = item.rankName;
            item.rankName = item.rankName.substring(0, 6) + "...";
          }
        });
        this.topList = res.data.data.data;
      });
    },
    indexTopNews() {
      let data = {
        type: "latest",
      };
      api.indexTopNews(data).then((res) => {
        let newList = [];
        let list = res.data.data.data;
        list.forEach((item) => {
          let data = {
            id: item.newsId,
            author: item.author,
            userIcon: item.authorFile,
            time: item.createTime,
            url: item.fileId,
            newsTitle: item.newsName,
            text: item.description,
            viewCount: item.newsViews,
            commentCount: item.commentNum,
            likeCount: item.total,
          };
          newList.push(data);
        });
        this.newList = newList;
      });
    },
    indexTopAssess() {
      api.indexTopAssess().then((res) => {
        this.AssessList = res.data.data.data;
        // console.log(this.newList);
      });
    },
    indexTopAComment() {
      api.indexTopAComment().then((res) => {
        // this.commentList = res.data.data.data
        let newList = [];
        let list = res.data.data.list;
        list.forEach((item) => {
          let data = {
            id:item.assessId,
            userIcon: item.memberIcon,
            username: item.memberName,
            time: item.createTime,
            url: item.apparatusFileId,
            newsTitle: item.apparatusName,
            apparatusType:item.apparatusType,
            viewCount: item.collectNum,
            commentCount: item.collectNum,
            likeCount: item.commentPros,
            text: item.description,
          };
          newList.push(data);
        });
        this.commentList = newList;
      });
    },

    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      var offsetTop = document.querySelector(".instrument").offsetTop;

      if (scrollTop > offsetTop - 85) {
        this.showTop = true;
      } else {
        this.showTop = false;
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
    if (this.interval) {
      clearInterval(this.interval);
    }
  },
};
</script>
<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(5px);
  opacity: 0;
}

.content {
  // background: #fbfbfb;
  min-height: 980px;
  margin-top: 85px;
  .content_banner {
    width: 1243px;
    background: #fbfbfb;
    // margin-bottom: 29px;
    padding-top: 45px;
    margin: 0 auto 29px;
    .banner {
      display: grid;
      grid-template-columns: 841px auto;
      grid-template-rows: auto auto;
      grid-column-gap: 25px;
      grid-row-gap: 25px;
      margin: 0 auto;
      .info_banner1 {
        // height: 533px;

        grid-row-start: 1;
        grid-row-end: 3;
        overflow: hidden;
        border-radius: 7px;
        font-size: 0;
        .info_banner_box {
          border-radius: 7px;
          overflow: hidden;
          display: inline-block;
          width: 100%;
          position: relative;

          img {
            width: 100%;
            // height: 100%;
            border-radius: 7px;
            transition: all 0.5s;
          }
          .banner_text {
            bottom: 0;
            width: calc(100% - 128px);
            padding: 25px 64px;
            position: absolute;
            left: 0;
            font-size: 24px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            background: linear-gradient(
              360deg,
              #001a2e 0%,
              rgba(0, 26, 46, 0) 100%
            );
            border-radius: 0px 0px 7px 7px;
            text-align: center;
            backdrop-filter: blur(4px);
          }
          i {
            font-size: 63px;
            color: #ec6969;
            position: absolute;
            left: 51px;
            top: 0;
          }
          .num {
            padding: 9px 17px;
            border-radius: 23px;
            background: rgba(126, 139, 149, 0.6000000238418579);
            font-size: 21px;
            font-family: HelveticaNeue-Medium, HelveticaNeue;
            font-weight: 500;
            color: #ffffff;
            position: absolute;
            top: 32px;
            right: 32px;
          }
        }

        &:hover {
          box-shadow: 0px 5px 21px 1px
            rgba(
              11.404168456792831,
              73.7658217549324,
              119.00000050663948,
              0.17000000178813934
            );
          img {
            transform: scale(1.05);
          }
        }
      }
      .info_banner2 {
        position: relative;
        overflow: hidden;
        border-radius: 7px;
        font-size: 0;
        .info_banner_box {
          border-radius: 7px;
          overflow: hidden;
          display: inline-block;
          width: 100%;
          position: relative;
          img {
            width: 100%;
            // height: 100%;
            border-radius: 7px;
            transition: all 0.5s;
          }
          .banner_text {
            bottom: 0;
            width: calc(100% - 128px);
            padding: 7px 64px;
            position: absolute;
            left: 0;
            font-size: 19px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            background: linear-gradient(
              360deg,
              #302861 0%,
              rgba(49, 41, 98, 0) 100%
            );
            border-radius: 0px 0px 7px 7px;
            text-align: center;
            backdrop-filter: blur(4px);
          }
          .num {
            padding: 3px 7px;
            border-radius: 23px;
            background: rgba(126, 139, 149, 0.6000000238418579);
            font-size: 13px;
            font-family: HelveticaNeue-Medium, HelveticaNeue;
            font-weight: 500;
            color: #ffffff;
            position: absolute;
            right: 15px;
            top: 15px;
          }
        }
        &:hover {
          img {
            box-shadow: 0px 5px 21px 1px
              rgba(
                11.404168456792831,
                73.7658217549324,
                119.00000050663948,
                0.17000000178813934
              );
            transform: scale(1.05);
          }
        }
      }
      .info_banner3 {
        position: relative;
        align-self: end;
        overflow: hidden;
        border-radius: 7px;
        font-size: 0;
        .info_banner_box {
          border-radius: 7px;
          overflow: hidden;
          display: inline-block;
          width: 100%;
          position: relative;
          img {
            width: 100%;
            // height: 100%;
            border-radius: 7px;
            transition: all 0.5s;
          }
          .banner_text {
            bottom: 0;
            width: calc(100% - 128px);
            padding: 7px 64px;
            position: absolute;
            left: 0;
            font-size: 19px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            background: linear-gradient(
              360deg,
              #002a34 0%,
              rgba(0, 43, 52, 0) 100%
            );
            border-radius: 0px 0px 7px 7px;
            text-align: center;
            backdrop-filter: blur(4px);
          }
          .num {
            padding: 3px 7px;
            border-radius: 23px;
            background: rgba(126, 139, 149, 0.6000000238418579);
            font-size: 13px;
            font-family: HelveticaNeue-Medium, HelveticaNeue;
            font-weight: 500;
            color: #ffffff;
            position: absolute;
            right: 15px;
            top: 15px;
          }
        }
        &:hover {
          img {
            box-shadow: 0px 5px 21px 1px
              rgba(
                11.404168456792831,
                73.7658217549324,
                119.00000050663948,
                0.17000000178813934
              );
            transform: scale(1.05);
          }
        }
      }
      .banner0 {
      }
      .banner_c-left,
      .banner_c-right {
        width: 860px;
        position: relative;
        //   display: grid;
        //   grid-template-columns: 860px 355px;
        //   grid-template-rows: 50% 50%;
        //   grid-column-gap: 28px;
        //  align-items: end;
        // align-content: center;

        .No {
          position: absolute;
          right: 32px;
          top: 32px;
          background: rgba(126, 139, 149, 0.6000000238418579);
          border-radius: 23px 23px 23px 23px;
          opacity: 1;
          padding: 9px 17px;
          font-size: 21px;
          font-family: HelveticaNeue-Medium, HelveticaNeue;
          font-weight: 500;
          color: #ffffff;
        }
        img {
          width: 100%;

          border-radius: 7px;
        }
        div {
          position: relative;
        }
        .banner_c-name {
          position: absolute;
          bottom: 0;
          padding: 25px 64px;
          font-size: 24px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .biaoqian {
          color: #ec6969;
          font-size: 63px;
          position: absolute;
          top: 0;
          left: 51px;
        }
        // display: flex;
        // flex-direction: column;
        // flex-wrap: wrap;
        // justify-content: center;
        // align-items: center;
      }
      .banner_c-right {
        width: 355px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  .content_hottop {
    padding-top: 40px;
    background: #ffffff;
    .hottop {
      width: 1243px;
      // height: 508px;
      margin: 0 auto;

      .hottop_c {
        width: 1243px;

        .hottop_c_btn {
        }
        .hottop_c_top {
          display: flex;
          align-items: center;
          // justify-content: space-between;
          .topbox {
            width: calc(20% - 22px);
            height: 380px;
            border-radius: 7px;
            transition: all 0.2s;
            z-index: 99;
            margin-left: 11px;
            margin-right: 11px;
            border: 1px solid transparent;
            overflow: hidden;
            &:first-child {
              margin-left: 0;
              width: calc(20% - 11px);
            }
            &:last-child {
              margin-right: 0;
              width: calc(20% - 11px);
            }
            &:hover {
              transform: scale(1.05);
              box-shadow: 0px 5px 36px 1px rgba(71, 83, 92, 0.2);
              border: 1px solid #d2d2d2;

              border-radius: 7 px;
              .topbox_t {
                background: #ffffff;
              }
            }
            .topbox_t {
              // width: 100%;
              height: 240px;
              padding: 9px;
              background: #fbfbfb;
              position: relative;

              .topbox_t_title {
                font-size: 19px;
                font-weight: 400;
                color: #5d5d5d;
                line-height: 22px;
              }
              .topbox_t_image {
                width: 157px;
                height: 150px;
                margin: 30px auto;
                img {
                  width: 100%;
                }
              }
              .bd {
                position: absolute;
                right: 0;
                top: 0;

                color: #ec6969;
              }
            }
            .topbox_b {
              // width: 100%;
              height: 109px;
              padding: 9px;
              background: #ffffff;
              .topbox_b_title {
                text-align: center;
                &.h1 {
                  margin-top: 40px;
                  font-size: 27px;
                  font-family: PingFang SC-medium, PingFang SC;
                  font-weight: bold;
                  color: #333333;
                }
                &.p {
                  margin-top: 20px;
                  font-size: 16px;
                  font-weight: 400;
                  color: #888888;
                  line-height: 19px;
                }
              }
            }
          }
        }
      }
    }
  }
  .content_instrument {
    padding-top: 40px;
    background: #ffffff;
    .instrument {
      width: 1243px;
      margin: 0 auto;
    }
  }
  .content_check {
    background: #ffffff;
    padding-top: 40px;
    .check {
      width: 1243px;
      margin: 0 auto;
    }
  }
  .returnTop {
    position: fixed;
    right: 12.7%;
    bottom: 300px;
    transition: all 0.5s;
  }
}
</style>