<template>
  <div
    class="instrument_c_btn baseBtn"
    :class="{ left: count, border: border }"
  >
    <div class="box" :class="{ url: !color,auto:color }">
      <button class="title" :class="{ color: color }">{{ first }}</button>
    </div>

    <button class="more" v-if="scende" @click="toPath()">{{ scende }}</button>
    <p v-if="count">
      经筛选，共<span>{{ count }}</span
      >个产品符合条件
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["first", "scende", "count", "border", "color", "to"],
  methods: {
    toPath() {
      this.$router.push(this.to);
    },
  },
};
</script>
<style lang="scss" scoped>
.baseBtn {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 35px;
  &.left {
    justify-content: flex-start;
  }
  &.border {
    border-bottom: 1px solid #d2d4d6;
    padding-bottom: 13px;
  }
  p {
    margin-left: 25px;
    font-size: 19px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
  }
  span {
    color: #ff5c5c;
    margin: 0 5px;
  }
}
.box {
  width: 205px;
  height: 88px;
  display: flex;
  align-items: flex-end;
  &.auto{
    width: auto;
    height: auto;
  }
}
.url {
  
  background: url("../assets/btn-background.png") no-repeat;
  background-position: 0 0;
  background-size: 100%;
}
.title {
  width: 180px;
  height: 53px;
  background: linear-gradient(
    90deg,
    #0092ff 0%,
    rgba(55, 182, 255, 0.8700000047683716) 100%
  );
  box-shadow: 0px 5px 9px 1px
    rgba(77.99999535083771, 191.00001901388168, 255, 0.25);
  border-radius: 39px 39px 39px 0px;
  border: none;
  font-size: 29px;
  font-weight: 800;
  color: #ffffff;
  font-family: PingFang SC-Regular;
  &.color {
    background: #fff;
    color: #333333;
    box-shadow: 0px 0px 0px 0px #fff;
    font-size: 21px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    width: auto;
    height: auto;

  }
}
.more {
  // width: 104px;
  height: 32px;
  border: 1px solid #868181;
  font-size: 19px;
  font-weight: 400;
  color: #868181;
  line-height: 22px;
  background: #ffffff;
  position: relative;
  padding-right: 21px;
  padding-left: 8px;
  font-family: PingFang SC-Regular, PingFang SC;
  &::after {
    content: "";
    display: block;
    border-top: 6px solid transparent;
    border-left: 9px solid #868181;
    border-bottom: 6px solid transparent;
    position: absolute;
    width: 0;
    height: 0;
    left: 90px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>